// lowercase-on-type.directive.ts
import { Directive, HostListener, ElementRef, inject } from '@angular/core';

@Directive({
    selector: '[appLowercaseOnType]',
    standalone: true
})
export class LowercaseOnTypeDirective {
  private el = inject(ElementRef);

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const start = this.el.nativeElement.selectionStart;
    const end = this.el.nativeElement.selectionEnd;
    const transformedValue = value.toLowerCase();
    this.el.nativeElement.value = transformedValue;
    this.el.nativeElement?.setSelectionRange(start, end);
  }
}
